import { useState, useRef } from 'react';

const logo = {
  alt: 'structural assessments',
  url: 'logo01.png',
  width: 175,
  href: 'https://www.structuralassessments.com.au/'

};

const menu = {
  linkClass:'fs-2 py-2 text-center',
  link1:{
    copy:'home',
    url: 'https://www.structuralassessments.com.au/'
  },
  link2:{
    copy:'about us',
    url:'#aboutus'
  },
  link3:{
    copy:'services',
    url:'#services'
  },
  link4:{
    copy:'contact',
    url: '#contact'
  },
  link5:{
    copy:'call us today: 0410125330',
    url: 'tel:+61410125330'
  }
};


const Menu = () =>  {

  const [show, setShow] = useState(false)
  const ref = useRef(null)
  const toggle = () => ( setShow(!show))

  // change nav color on scroll

  const [color,setColor] =useState(false)
  const changeColor =()=>{
    if (window.scrollY >=80){
      setColor(true)
    } else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', changeColor)

return(
    <>
    <nav className={ color ? 'navbar fixed-top bg-trans-black shadow' : 'navbar fixed-top'} role='navigation'>
    <div className='container'>
    <a className='navbar-brand' href={logo.href}><img src={logo.url} alt={logo.alt} width={logo.width} /></a>
    <div className='col d-none d-md-flex justify-content-end menu-links'>
            <a href={menu.link1.url}>{menu.link1.copy}</a>
            <a href={menu.link2.url}>{menu.link2.copy}</a>
            <a href={menu.link3.url}>{menu.link3.copy}</a>
            <a href={menu.link4.url}>{menu.link4.copy}</a>
            <a href={menu.link5.url}>{menu.link5.copy}</a>
    </div>
    <button className='navbar-toggler d-md-none' type='button' aria-label='Toggle navigation'>
      <span className='navbar-toggler-icon' onClick={toggle}></span>
    </button>
    <div className='navbar-collapse flex-row-reverse bg-jordyblue'>
        {show &&
          <div className='navbar-nav menu-links'>
            <a ref={ref} onClick={toggle} href={menu.link1.url} className={menu.linkClass}>{menu.link1.copy}</a>
            <a ref={ref} onClick={toggle} href={menu.link2.url} className={menu.linkClass}>{menu.link2.copy}</a>
            <a ref={ref} onClick={toggle} href={menu.link3.url} className={menu.linkClass}>{menu.link3.copy}</a>
            <a ref={ref} onClick={toggle} href={menu.link4.url} className={menu.linkClass}>{menu.link4.copy}</a>
            <a ref={ref} onClick={toggle} href={menu.link5.url} className={menu.linkClass}>{menu.link5.copy}</a>
          </div>
        }
    </div>
    </div>
    </nav>
    </>
  )
}



export default Menu;
